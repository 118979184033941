var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[[_c('v-breadcrumbs',[[_c('v-breadcrumbs-item',[_c('h3',[_vm._v("DDHH")])]),_c('v-breadcrumbs-item',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-home")])],1),_c('v-breadcrumbs-item',[_vm._v(" TIPO DESARMONÍA ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","rounded":"","to":{
            name: 'humansRights.createDisharmony',
            params: {
              isFemale: this.isFemale
            }
          }}},[_vm._v(" Nueva Desarmonía ")])]],2)],_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"4","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"footer-props":{
          'items-per-page-text': 'Filas por página',
          'items-per-page-options': [5,10,15]
        },"headers":_vm.headers,"items":_vm.events,"items-per-page":15,"search":_vm.search,"item-key":"id"},scopedSlots:_vm._u([{key:"item.state",fn:function({ item }){return [_c('v-chip',{attrs:{"outlined":"","color":_vm.eventStateColor(item.state.name)}},[_vm._v(" "+_vm._s(item.state.name || "SIN ESTADO ASIGNADO")+" ")])]}},{key:"item.action",fn:function({ item }){return [_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.editCase(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-circle-edit-outline")]),_vm._v(" Editar ")],1)],1)],1)],1)]}}])})],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }