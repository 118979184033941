<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-icon>mdi-email</v-icon>
        <h3 class="ml-2">Plantilla de correo</h3>
      </v-card-title>
      <v-card-text>
        <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
        <v-btn rounded color="primary" class="mt-2" @click="saveTemplate()">
          <!-- icon save-->
          <v-icon>mdi-content-save</v-icon>
          Guardar
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {storeMailTemplate, listMailTemplate} from "@/services/humansRights";
import Swal from "sweetalert2";

export default {
  name: "mailTemplate.vue",
  components: {
    ckeditor : CKEditor.component,
  },
  data(){
    return {
      editor: ClassicEditor,
      editorData: '<p>Hello from CKEditor 5!</p>',
      editorConfig : {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'imageUpload']
      }
    }
  },
  methods: {
    saveTemplate(){
      this.showLoader();
      storeMailTemplate({
        template : this.editorData
      })
        .then(() => {
          this.hideLoader();
          Swal.fire({
            title: 'Plantilla guardada',
            text: 'La plantilla se ha guardado correctamente',
            icon: 'success',
            confirmButtonText: 'Aceptar'
          })
        })
        .catch(error => {
          this.hideLoader()
          console.log(error);
          Swal.fire({
            title: 'Error',
            text: 'Ha ocurrido un error al guardar la plantilla',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          })
        })
    }
  },
  created() {
    this.showLoader();
    listMailTemplate()
      .then((response) => {
        this.hideLoader();
        this.editorData = response.data.length ? response.data.pop().template : '<p>Escriba aqui su plantilla</p>';
      })
      .catch(error => {
        this.hideLoader();
        console.log(error);
        Swal.fire({
          title: 'Error',
          text: 'Ha ocurrido un error al obtener la plantilla',
          icon: 'error',
          confirmButtonText: 'Aceptar'
        })
      })
  }
}
</script>

<style scoped>

</style>
