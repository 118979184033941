<template>
  <v-card>
    <v-tabs vertical>
      <v-subheader>Valores referencia</v-subheader>
      <v-divider></v-divider>
      <v-tab>
        <v-icon left size="medium">fas fa-user-check</v-icon>
        Tipo desarmonía
      </v-tab>
      <v-tab>
        <v-icon left size="medium">fas fa-user-tag</v-icon>
        Tipo evento
      </v-tab>
      <v-tab>
        <v-icon left size="medium">fas fa-user-tag</v-icon>
        Sub tipo evento
      </v-tab>
      <v-tab v-if="isFemale === 'false'">
        <v-icon left size="medium">fas fa-user-tag</v-icon>
        Petición clases
      </v-tab>
      <v-tab>
        <v-icon left size="medium">fas fa-user-tag</v-icon>
        Estados
      </v-tab>
      <v-tab v-if="isFemale === 'false'">
        <v-icon left size="medium">fas fa-user-tag</v-icon>
        Datos requeridos subtipos de eventos
      </v-tab>
      <v-tab v-if="isFemale === 'false'">
        <v-icon left size="medium">fas fa-user-tag</v-icon>
        Opciones tipo de eventos
      </v-tab>
      <v-tab>
        <v-icon left size="medium">fas fa-user-tag</v-icon>
        Plantilla de correo
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <ListDisharmonyType :is-female="isFemale === 'true'" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <ListEventType :is-female="isFemale === 'true'" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <ListSubEventType :is-female="isFemale === 'true'" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="isFemale === 'false'">
        <v-card flat>
          <v-card-text>
            <ListRequestClass />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <ListStates />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="isFemale === 'false'">
        <v-card flat>
          <v-card-text>
            <ListEventTypeRequires />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="isFemale === 'false'">
        <v-card flat>
          <v-card-text>
            <ListEventTypeRequiresOption />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <MailTemplate/>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>
<script>
import ListDisharmonyType from "@/views/HumansRights/ListDisharmonyType";
import ListEventType from "@/views/HumansRights/ListEventType";
import ListSubEventType from "@/views/HumansRights/ListSubEventType";
import ListRequestClass from "@/views/HumansRights/ListRequestClass";
import ListStates from "@/views/HumansRights/ListStates";
import ListEventTypeRequires from "@/views/HumansRights/ListEventTypeRequires";
import ListEventTypeRequiresOption from "@/views/HumansRights/ListEventTypeRequiresOption";
import MailTemplate from "@/views/HumansRights/mailTemplate/mailTemplate";

export default {
  components: {
    MailTemplate,
    ListDisharmonyType,
    ListEventType,
    ListSubEventType,
    ListRequestClass,
    ListStates,
    ListEventTypeRequires,
    ListEventTypeRequiresOption,
  },
  data() {
    return {};
  },
  props : {
    isFemale: {
      type: String,
      default: 'false'
    }
  },
  async created() {
    this.$store.commit("SET_SYNC_DATA_SOURCE_FLAG", true);
  }
};
</script>
<style scoped>
.v-tab {
  justify-content: start !important;
}
</style>
